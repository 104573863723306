<template>
  <div class="flightbooking_form_rightbox">

    <right-form-sticky-header />

    <sabre-flight-booking-form />

  </div>
</template>

<script>

export default {
  name: 'flight-booking-form-right-box',
  components: {
    RightFormStickyHeader: () => import('./rightFormStickyHeader'),
    SabreFlightBookingForm: () => import('./sabreFlightBookingForm'),
  },
};
</script>
